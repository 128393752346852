:root {
    --ff-primary: 'Poppins', sans-serif;
    --ff-secondary: 'Teko', sans-serif;
    --ff-additional: 'Inter', sans-serif;
    --clr-primary: #fe6845;
    --spacer: 1rem;
    --clr-blue: #4090ff;
    --clr-dark: #000;
    --clr-white: #ffffff;
    --clr-header: #4A4A4A;
    --clr-purple-gradient: linear-gradient(-45deg, #6f59e3, #5439e1, #4f2dff, #4926ff);
    --clr-purple: #6f59e3;
    --clr-green: #40b25a;
    --clr-grey: #eee;
  }
  
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  
  h1,
  h2,
  h3,
  p {
    margin: 0;
  }
  
  h1 {
    margin: 3rem 0;
    text-align: center;
    font-size: clamp(2rem, 1rem + 3vw, 6rem);
    font-weight: 300;
    line-height: 1;
    opacity: 0.2;
  }

  body {
    font-family: var(--ff-primary);
    min-height: 100vh;
    font-size: 1em;
    line-height: 1.6;
    color: var(--clr-body);
    background: var(--clr-bg);
    margin: 0px;
}

a {
    text-decoration: none;
}

ul:not(.navbar) li a {
    color: var(--clr-dark);
    text-decoration: underline;
};

li a:hover {
    text-decoration: underline;
    color: var(--clr-primary);
}

.nav-container {
    position: sticky;
    top: 0;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    z-index: 10;
    background-color: var(--clr-white);
}

nav.menu {
    display: flex;
}

ul.navbar li {
    display: inline;
    margin-left: 10px;
}

.logo,
.navbar {
    margin: 15px auto;
    padding: 0;

}

nav a {
    text-decoration: none;
    color: #112e51;
}

ul.navbar {
    display: flex;
}

ul.navbar>li a {
    padding: 8px;
    border: 1px solid transparent;
    border-radius: 8px;
    background-color: var(--clr-primary);
    color: var(--clr-white);
}

ul.navbar>li:nth-last-child(2) a {
    background: var(--clr-purple);
}

ul.navbar>li:last-child a {
    background: var(--clr-green);
}

ul.navbar>li a:hover {
    padding: 8px;
    border-radius: 8px;
}

@media screen and (max-width: 600px) {
    nav.menu {
        flex-wrap: wrap;
        text-align: center;
        padding-bottom: 1rem;
    }

    .logo,
    .navbar {
        flex: 100%;
    }

    ul.navbar {
        justify-content: center;
    }
}

.tech-used {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.tech-used li {
    list-style-type: none;
    text-align: center;
    width: 90px;
    height: 90px;
}

.tech-used li img {
    border-radius: 50%;
    object-fit: cover;
    width: 80px;
    aspect-ratio: 1 / 1;  
    /* position: absolute; */
    top: 100%;
    left: 0;
    filter: drop-shadow(0px 3px 3px rgba(0,0,0,.05));
    /* transform: translateX(-50%); */
    animation: bubble_float 10s linear forwards;
  }
  @keyframes bubble_float {
    100% { 
      top: -130px; 
      transform: translateX(-50%) rotate(360deg); 
    }
  }

.section {
    padding: 2em;
}

.accessibility {
    border-radius: 158px 0px 0px 0px;
}

.accessibility .content_card {
    box-shadow: 0.5rem 0.5rem var(--clr-grey);
}

.gradient_background {
    background: linear-gradient(-45deg, #fccb37, #FFA046, #ff7142, #fd4f31);
	background-size: 400% 400%;
	animation: gradient 5s ease infinite;
}

.gradient_background.q4inc {
    background: linear-gradient(-87deg, #fccb37, #FFA046, #ff7142, #ff5c3f);
    background-size: 400% 400%;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.content_card {
    background-color: var(--clr-white);
    max-width: 60rem;
    margin: 0 auto;
    border-radius: 8px;
    padding: 1em;
}

@media (max-width: 1104px) {
    .gradient_background {
        border-radius: 0px;
    }
    .gradient_background .content_card {
        border-radius: 5px;
    }
}

h2:not(.footer h2) {
    font-family: var(--ff-secondary);
    color: var(--clr-header);
}

.splide-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.splide__pagination.splide__pagination--ltr {
    bottom: -2.5em !important;
}

.splide__pagination.splide__pagination--ltr .splide__pagination__page {
    background: #fffbfb;
    border: 1px solid var(--clr-dark);
}

.splide__pagination.splide__pagination--ltr .splide__pagination__page.is-active {
    background: var(--clr-dark);
}

.dark_background {
    background-color: var(--clr-white);
}

.grid {
    padding: 1em;
    --gap: 1em;
    --columns: 3;
    max-width: 60rem;
    margin: 0 auto;
    columns: var(--columns);
    gap: var(--gap);
    position: relative;
}

@media (max-width: 576px) {
    .grid {
        columns: 2;
        --gap: 1em;
        padding: 0 5px;
    }
}

@media (max-width: 453px) {
    .grid {
        columns: 1;
    }
}
  
.grid>* {
    break-inside: avoid;
    margin-bottom: var(--gap);
}

@supports (grid-template-rows: masonry) {
    .grid {
        display: grid;
        grid-template-columns: repeat(var(--columns), 1fr);
        grid-template-rows: masonry;
        grid-auto-flow: dense;
    }

    .grid>* {
        margin-bottom: 0em;
    }
}

.featured {
    grid-column: span 2;
}

.flow>*+* {
    margin-top: var(--flow-space, var(--spacer));
}

.content {
    padding: 1.5em;
    box-shadow: 0 0 3em rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    text-align: center;
    box-shadow: 0.5rem 0.5rem var(--clr-grey);
}

.content:nth-child(2n+3) {
    border: 4px solid var(--clr-blue);
}

.content:nth-child(2n+3) .title {
    color: var(--clr-blue);
}

.content:nth-child(2n+3) .title::after {
    background-color: var(--clr-blue);
}

.content:nth-child(2n+2) {
    border: 4px solid var(--clr-green);
}

.content:nth-child(2n+2) .title {
    color: var(--clr-green);
}

.content:nth-child(2n+2) .title::after {
    background-color: var(--clr-green);
}

.content:nth-child(3n+1) {
    border: 4px solid var(--clr-purple);
}

.content:nth-child(3n+1) .title {
    color: var(--clr-purple);
}

.content:nth-child(3n+1) .title::after {
    background-color: var(--clr-purple);
} 

.content:nth-child(4n+4) {
    border: 4px solid var(--clr-primary);
}

.content:nth-child(4n+4) .title {
    color: var(--clr-primary);
}

.content:nth-child(4n+4) .title::after {
    background-color: var(--clr-primary);
}

.title {
    font-weight: 900;
    line-height: 1.2;
    margin-bottom: 0.9em;
    position: relative;
    text-align: left;
    font-size: 1rem;
}

.title::after {
	position: absolute;
    content: "";
    width: 100%;
    height: 3px;
    /* background-color: whitesmoke; */
    left: 0;
    bottom: -2px;
    transition: all 0.4s ease-in-out;
}

.content:hover .title::after {
	width: 0%;
	left: 50%;
}

img {
    max-width: 100%;
}

.q4inc .section {
    background-color: var(--clr-white);
    border-radius: 198px 0px 0px 0px;
}

.content img {
    filter: grayscale();
	border: 2px solid whitesmoke;
	box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.5);
	margin-bottom: 1rem;
	transition: filter 0.4s ease-in-out;
    object-fit: contain;
}

@media (max-width: 453px) {
    .content {
        padding: 1em;
    }
    .content img {
        width: 200px;
        height: 200px;
        /* border-radius: 50%; */
        filter: none;
    }
}

.content:hover img {
	filter: none;
}

.autotrader {
    background-color: var(--clr-blue);
}

.autotrader .content_card {
    box-shadow: 0.5rem 0.5rem var(--clr-grey);
}

.autotrader .portfolio {
    display: flex;
    justify-content: space-between;
}

.autotrader .portfolio > div {
    width: 50%;
}

.autotrader .clip_card {
    background: url(../src/images/ford.png) center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    height: 228px;
    width: 50%;
}

.autotrader .clip_card:before {
    position: absolute;
    top: 0px;
    left: 0;
    height: 100%;
    width: 80%;
    -webkit-clip-path: polygon(0 0, 100% 0, 46% 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 0, 46% 100%, 0% 100%);
    content: "";
    background: url(../src/images/bmw.png) center center;
    background-size: cover;
    background-repeat: no-repeat;
}

@media (max-width: 718px) {
    .clip_card {
        display: none;
    }
    .autotrader .portfolio {
        justify-content: center;
    }

    .autotrader .portfolio > div {
        width: 100%;
    }
}

.text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.text ul {
    padding-left: 0px;
}

.freelance {
    position: relative;
}

.environmental {
    display: flex;
    flex-direction: row;
}

.environmental div:not(.circle) {
    width: 45%;
}

.circle {
    height: 220px;
    width: 220px;
    background-color: #FFA046;
    border-radius: 50%;
    box-shadow: 0.5rem 0.5rem var(--clr-grey);
    margin: 0 auto;
}

.circle:after {
    content: "";
    height: 80px;
    width: 80px;
    background-color: var(--clr-primary);
    position: absolute;
    border-radius: 50%;
    top: 35%;
    left: 11%;
}

@media (max-width: 588px) {
    .environmental div:not(.circle) {
        width: 100%;
    }
    .circle {
        position: absolute;
        bottom: 2%;
        right:10%;
        height: 100px;
        width: 100px;
    }

    .circle:after {
        height: 50px;
        width: 50px;
        bottom: 6%;
        left: -15%;
    }
}

.footer {
    background: var(--clr-purple-gradient);
    border-radius: 0px 0px 158px 0px;
    color: var(--clr-white);
}

.footer h2 {
    text-align: center;
    font-family: var(--ff-secondary);
    font-size: 1.6rem;
}

.footer .content_card {
    background-color: transparent;
}

@media (max-width: 1104px) {
    .footer .content_card {
        border-radius: 0px 0px 158px 0px;
    }
}

.footer .content_card li a {
    color: var(--clr-white);
}

.footer ul {
    display: flex;
    justify-content: space-around;
    list-style-type: none;
    padding-left: 0px;
}

@media (max-width: 382px) {
    .footer ul {
        flex-direction: column;
        align-items: center;
    }
}

/* Travel Page Styling */
.travel_page > div:first-child {
    background-color: #F55A5A;
    color: var(--clr-white);
    padding: 20px 0px;
    text-align: center;
}

.mockup_source, .mockup_link {
    font-size: 10px;
}

.mockup_link {
    color: var(--clr-white);
    text-decoration: underline;
}

.card_parent {
    padding: 60px 40px 0px 40px;
}

.card_container {
    display: flex;
    padding: 1rem;
}

.card_container img:not(.map_pin) {
    width: 125px;
    height: 168px;
    border-radius: 5px;
    object-fit: cover;
    margin-right: 2rem;
}

.card_container .map_pin {
    width: 11px;
    height: 15px;
    margin-right: 5px;
}

.card_info {
    display: flex;
    flex-direction: column;
}

.location_div, .time_length {
    font-family: var(--ff-additional);
}

.google_maps {
    margin-left: 5px;
    color: #393939;
    text-decoration: underline;
}

.location_div span {
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.17em;
    text-transform: uppercase;
    font-family: var(--ff-additional);
}

.time_length {
    color: #2B283A;
    font-weight: 700;
}

@media (max-width: 768px){
    .intro {
        padding: 20px 10px;
    }
    .intro > span:first-child(), p {
        font-size: 0.8em;
    }
    .card_container {
        flex-wrap: wrap;
    }
    .card_container img:not(.map_pin) {
        width: auto;
        height: auto;
    }
    .location_div {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .google_maps {
        margin-left: 0px;
    }
}

/* Travel Page Styling ends */